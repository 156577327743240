<template>
  <!--begin::details View-->
  <div
    class="card mb-5 mb-xl-10"
    id="kt_profile_details_view"
    v-if="isDataReady"
  >
    <!--begin::Card header-->
    <div class="card-header cursor-pointer">
      <!--begin::Card title-->
      <div class="card-title m-0">
        <h3 class="fw-bolder m-0">Profile Details</h3>
      </div>
      <!--end::Card title-->

      <!--begin::Action-->
      <!--<router-link
        :to="{
          name: 'account-settings',
          params: {
            accountid: accountid,
          },
        }"
        class="btn btn-primary align-self-center"
      >
        Edit Profile</router-link
      >-->
      <!--end::Action-->
    </div>
    <!--begin::Card header-->

    <!--begin::Card body-->
    <div class="card-body p-9">
      <!--begin::Row-->
      <div class="row mb-7">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted">Full Name</label>
        <!--end::Label-->

        <!--begin::Col-->
        <div class="col-lg-8">
          <span class="fw-bolder fs-6 text-dark"
            >{{ accountInfo.firstName }} {{ accountInfo.lastName }}</span
          >
        </div>
        <!--end::Col-->
      </div>
      <!--end::Row-->

      <!--begin::Input group-->
      <div class="row mb-7">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted">Company</label>
        <!--end::Label-->

        <!--begin::Col-->
        <div class="col-lg-8 fv-row">
          <span class="fw-bolder fs-6">{{ accountInfo.companyName }}</span>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Input group-->

      <!--begin::Input group-->
      <div class="row mb-7">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted">Job Title</label>
        <!--end::Label-->

        <!--begin::Col-->
        <div class="col-lg-8 fv-row">
          <span class="fw-bolder fs-6">{{ accountInfo.jobTitle }}</span>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Input group-->

      <!--begin::Input group-->
      <div class="row mb-7">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted">
          Email
          <i
            class="fas fa-exclamation-circle ms-1 fs-7"
            data-bs-toggle="tooltip"
            title="Phone number must be active"
          ></i>
        </label>
        <!--end::Label-->

        <!--begin::Col-->
        <div class="col-lg-8 d-flex align-items-center">
          <span class="fw-bolder fs-6">{{ accountInfo.email }}</span>

          <span
            v-show="accountInfo.emailConfirmed"
            class="badge badge-success mx-2"
            >Verified</span
          ><span
            v-show="!accountInfo.emailConfirmed"
            class="badge badge-danger mx-2"
            >Not Verified</span
          >
        </div>
        <!--end::Col-->
      </div>
      <!--end::Input group-->

      <!--begin::Input group-->
      <div class="row mb-7">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted">
          Contact Phone
          <i
            class="fas fa-exclamation-circle ms-1 fs-7"
            data-bs-toggle="tooltip"
            title="Phone number must be active"
          ></i>
        </label>
        <!--end::Label-->

        <!--begin::Col-->
        <div class="col-lg-8 d-flex align-items-center">
          <span v-if="accountInfo.phoneNumber" class="fw-bolder fs-6">{{
            accountInfo.phoneNumber
          }}</span
          ><span v-else class="fw-bolder fs-6"
            >No phone information available</span
          >
          <div v-if="accountInfo.phoneNumber">
            <span
              v-show="accountInfo.phoneNumberConfirmed"
              class="badge badge-success mx-2"
              >Verified</span
            ><span
              v-show="!accountInfo.phoneNumberConfirmed"
              class="badge badge-danger mx-2"
              >Not Verified</span
            >
          </div>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Input group-->

      <!--begin::Input group-->
      <div class="row mb-7">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted">Role</label>
        <!--end::Label-->

        <!--begin::Col-->
        <div class="col-lg-8">
          <span
            v-for="(item, index2) in accountInfo.userIdentityRoles"
            :key="item.id"
            class="fw-bolder fs-6 text-dark"
            :respondentId="'role_' + index2"
            >{{ item.name }}</span
          >
        </div>
        <!--end::Col-->
      </div>
      <!--end::Input group-->
    </div>
    <!--end::Card body-->
  </div>
  <div v-else>
    <TableSkeleton></TableSkeleton>
  </div>
  <!--end::details View-->
</template>

<script lang="ts">
import { defineComponent, onMounted, computed, watch, ref } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.js";
import TableSkeleton from "@/components/widgets/skeletons/TableSkeleton.vue";

export default defineComponent({
  name: "user-account-overview",
  props: ["accountid"],
  components: { TableSkeleton },
  setup(props) {
    const store = useStore();
    const isDataReady = ref(false);

    const accountInfo = computed(() => {
      return store.getters.getSelectedApplicationUserProfile;
    });

    store
      .dispatch(Actions.GET_APPLICATION_USER_PROFILE, props.accountid)
      .then(() => {
        //
      })
      .catch(() => {
        Swal.fire({
          text: store.getters.getErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText:
            "There was a problem retrieving the users profile.",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      });

    onMounted(() => {
      setCurrentPageTitle("User Profile");
      setCurrentPageBreadcrumbs("Overview", ["User"]);
    });

    watch(accountInfo, () => {
      isDataReady.value = true;
    });

    return {
      accountInfo,
      isDataReady,
    };
  },
});
</script>
